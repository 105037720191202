// import * as faceapi from "face-api.js";
// import { ref } from "vue";
// import reportEmotionService from "../service/http/report/reportemotion.service";
// import reportEmotionModel from "../service/http/report/model/reportemotion";
// export function useFaceApi() {
//   let remoteFaceApi = ref(false);
//   const initFaceApi = () => {
//     try {
//       const video = document.querySelector("#remote-media-div > video");
//       const videoContainer = document.querySelector("#remote-media-div");

//       console.log("initFaceApi");
//       Promise.all([
//         faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
//         faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
//         faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
//         faceapi.nets.faceExpressionNet.loadFromUri("/models"),
//       ]);

//       const canvas = faceapi.createCanvasFromMedia(video);
//       canvas.style =
//         "z-index: 2; position: absolute; margin-top: 30px !important;";
//       videoContainer.appendChild(canvas);

//       const boxSize = {
//         width: video.clientWidth,
//         height: video.clientHeight,
//       };

//       faceapi.matchDimensions(canvas, boxSize);

//       setInterval(async () => {
//         //async
//         // await
//         const detections = await faceapi
//           .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
//           .withFaceLandmarks()
//           .withFaceExpressions();

//         canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
//         const resizedDetections = faceapi.resizeResults(detections, boxSize);

//         if (detections.length > 0 && detections[0].expressions) {
//           let maxExpression = "";
//           let maxValue = 0;

//           Object.entries(detections[0].expressions).forEach(
//             ([expression, value]) => {
//               if (value > maxValue) {
//                 maxExpression = expression;
//                 maxValue = value;
//               }
//             }
//           );

//           console.log(
//             "En yüksek beklentiye sahip duygu: " +
//               maxExpression +
//               " (" +
//               maxValue +
//               ")"
//           );
//           var appointmentId = localStorage.getItem("appointment_id");
//           var setEmotionVar = reportEmotionModel.createEmotionClient(
//             appointmentId,
//             maxExpression
//           );
//           await reportEmotionService.setEmotion(setEmotionVar);
//           console.log(setEmotionVar);
//         }
//         faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
//       }, 2000);
//       return true;
//     } catch (error) {
//       return false;
//     }
//   };
//   return { initFaceApi, remoteFaceApi, faceapi };
// }
import * as faceapi from "face-api.js";
import { ref } from "vue";
import reportEmotionService from "../service/http/report/reportemotion.service";
import reportEmotionModel from "../service/http/report/model/reportemotion";

export function useFaceApi() {
  let remoteFaceApi = ref(false);
  let faceDetectionInterval = ref(null); // Yüz algılama için interval'ı saklamak için

  const initFaceApi = async () => {
    try {
      const video = document.querySelector("#remote-media-div > video");
      const videoContainer = document.querySelector("#remote-media-div");

      console.log("initFaceApi");
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
        faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
        faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
        faceapi.nets.faceExpressionNet.loadFromUri("/models"),
      ]);

      const canvas = faceapi.createCanvasFromMedia(video);
      canvas.style =
        "z-index: 2; position: absolute; margin-top: 30px !important;";
      videoContainer.appendChild(canvas);

      const boxSize = {
        width: video.clientWidth,
        height: video.clientHeight,
      };

      faceapi.matchDimensions(canvas, boxSize);

      faceDetectionInterval.value = setInterval(async () => {
        const detections = await faceapi
          .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
          .withFaceLandmarks()
          .withFaceExpressions();

        canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
        const resizedDetections = faceapi.resizeResults(detections, boxSize);
        console.log("detections[0]");
        console.log(detections[0]);

        if (detections.length > 0 && detections[0].expressions) {
          let maxExpression = "";
          let maxValue = 0;

          Object.entries(detections[0].expressions).forEach(
            ([expression, value]) => {
              if (value > maxValue) {
                maxExpression = expression;
                maxValue = value;
              }
            }
          );

          var appointmentId = localStorage.getItem("appointment_id");
          var setEmotionVar = reportEmotionModel.createEmotionClient(
            appointmentId,
            maxExpression
          );
          await reportEmotionService.setEmotion(setEmotionVar);
        }

        faceapi.draw.drawFaceExpressions(canvas, resizedDetections);
      }, 2000);

      return true;
    } catch (error) {
      console.error("initFaceApi error:", error);
      return false;
    }
  };

  const closeFaceApi = () => {
    if (faceDetectionInterval.value) {
      clearInterval(faceDetectionInterval.value);
      faceDetectionInterval.value = null;
    }

    const canvas = document.querySelector("#remote-media-div > canvas");
    if (canvas) {
      canvas.remove();
    }

    console.log("Face API kapatıldı");
  };

  return { initFaceApi, closeFaceApi, remoteFaceApi };
}
