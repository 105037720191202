import axios from "axios";

const getUserDetail = async (id) => {
  try {
    const response = await axios.get(
      `kivicare/api/v1/user/get-detail?ID=${id}`
    );

    if (response.status == 200) {
      //   console.log("Burda rapor oluşturabilirim");
      //   if (response.data.role === "doctor") {
      //     console.log("Burda doktor raporu oluşturabilirim");
      //   }
      console.log(response);
      console.log(response.data);
      console.log(response.data.first_name);

      return response.data;
    }
    return null;
  } catch (e) {
    return null; //UserNotFound
  }
};

const deleteUser = async (id) => {
  try {
    const response = await axios.delete("Users", {
      data: {
        id,
      },
    });
    if (response.status == 200) {
      return response;
    }
    return null;
  } catch (e) {
    return null; //UserNotFound
  }
};

const addUser = async (user) => {
  console.log(user);

  try {
    const response = await axios.post("Users", {
      ...user,
    });
    console.log(response);
    if (response.status == 200) {
      console.log("Başarılı");
      console.log();
    } else {
      console.log("Başarısız");
    }

    return response;
  } catch (e) {
    console.log("başarısız");
    console.log(e);
    return e.response; //UserNotFound
  }
};

const updateUser = async (user) => {
  console.log(user);

  try {
    const response = await axios.put("Users", {
      ...user,
    });
    console.log(response);
    if (response.status == 200) {
      console.log("Başarılı");
      console.log();
    } else {
      console.log("Başarısız");
    }

    return response;
  } catch (e) {
    console.log("başarısız");
    console.log(e);
    return e.response; //UserNotFound
  }
};

export default {
  getUserDetail,
  deleteUser,
  addUser,
  updateUser,
};
