import axios from "axios";

const getAppointment = async () => {
  try {
    const response = await axios.get(
      `kivicare/api/v1/appointment/get-appointment`
    );

    if (response.status == 200) {
      // console.log("burada randevu oluşturulabilir");
      // console.log(response.data);
      return response.data;
    }
    return null;
  } catch (e) {
    return null; //UserNotFound
  }
};

export default {
  getAppointment,
};
