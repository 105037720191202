<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3" style="margin-top: 5rem">
        <div class="form-demo">
          <div class="p-d-flex p-jc-center">
            <PrimeCard>
              <template #title> Lütfen Giriş Yapınız </template>
              <template #content>
                <form class="p-fluid" @submit.prevent="handleSubmit(!v$.$invalid)">
                  <div class="p-field" style="margin-bottom: 2rem">
                    <div class="p-float-label">
                      <PrimeInputText id="name" v-model="v$.name.$model"
                        :class="{ 'p-invalid': v$.name.$invalid && submitted }" />
                      <label for="name" :class="{ 'p-error': v$.name.$invalid && submitted }">Telefon*</label>
                    </div>
                    <small v-if="(v$.name.$invalid && submitted) ||
                      v$.name.$pending.$response
                      " class="p-error">{{
    v$.name.required.$message.replace("Value", "Name")
  }}</small>
                  </div>
                  <div class="p-field">
                    <div class="p-float-label">
                      <PrimePassword :feedback="false" id="password" v-model="v$.password.$model" :class="{
                        'p-invalid': v$.password.$invalid && submitted,
                      }" toggle-mask>
                        <template #header>
                          <h6>Pick a password</h6>
                        </template>
                        <template #footer="sp">
                          {{ sp.level }}
                          <PrimeDivider />
                          <p class="p-mt-2">Suggestions</p>
                          <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                            <li>At least one lowercase</li>
                            <li>At least one uppercase</li>
                            <li>At least one numeric</li>
                            <li>Minimum 8 characters</li>
                          </ul>
                        </template>
                      </PrimePassword>
                      <label for="password" :class="{
                        'p-error': v$.password.$invalid && submitted,
                      }">Şifre*</label>
                    </div>
                    <small v-if="(v$.password.$invalid && submitted) ||
                      v$.password.$pending.$response
                      " class="p-error">{{
    v$.password.required.$message.replace(
      "Value",
      "Password"
    )
  }}</small>
                  </div>
                  <PrimeButton type="submit" label="Giriş Yap" class="p-mt-2" @click="onSubmit" />
                </form>
              </template>
            </PrimeCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import authService from "../service/http/auth.service";
export default {
  props: {
    apiService: { type: Function },
    getLoader: { type: Function },
    urlLoginName: { type: String },
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    const userName = this.urlLoginName;
    return {
      name: userName,
      password: "",
    };
  },
  countryService: null,
  validations() {
    return {
      name: {
        required,
      },
      password: {
        required,
      },
    };
  },
  methods: {
    handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }
    },
    onSubmit: async function () {
      await this.getLoader();
      const user = await authService.login(this.name, this.password);
      if (user) {
        this.$emit("login-event", user);
      }

      this.apiService();
    },
  },
};
</script>

<style lang="scss" scoped>
.form-demo {
  .card {
    form {
      margin-top: 2rem;
    }

    .p-field {
      margin-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 960px) {
    .card {
      width: 80%;
    }
  }

  .p-field-checkbox>label,
  .p-field-radiobutton>label {
    margin-left: 0.5rem;
    line-height: 1;
  }

  .p-mt-2 {
    margin-top: 0.5rem !important;
  }
}
</style>
