const createEmotionClient = (appointment_id, emotion) => {
  const emotionClient = {
    appointment_id: appointment_id,
    emotion: emotion,
  };
  return emotionClient;
};

export default {
  createEmotionClient,
};
