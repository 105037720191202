const createAppointmentClient = (
  appointmentId,
  patientId,
  doctorId,
  mobileNumber,
  displayName
) => {
  const appointmentClient = {
    appointment_id: appointmentId,
    patient_id: patientId,
    doctor_id: doctorId,
    user_mobilenumber: mobileNumber,
    user_display_name: displayName,
  };

  return appointmentClient;
};
export default {
  createAppointmentClient,
};
