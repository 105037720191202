import axios from "axios";

const setEmotion = async (sheetEmotion) => {
  try {
    const response = await axios.post(
      `https://report.dilvekonusmaterapim.com/api/emotion`,
      {
        ...sheetEmotion,
      }
    );

    if (response.status == 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    return null; //UserNotFound
  }
};

export default {
  setEmotion,
};
