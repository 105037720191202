const createAnswerClient = (
  appointment_id,
  sheet_id,
  is_true,
  word,
  word_pic
) => {
  const answerClient = {
    appointment_id: appointment_id,
    sheet_id: sheet_id,
    is_true: is_true,
    word: word,
    word_pic: word_pic,
  };
  return answerClient;
};
export default {
  createAnswerClient,
};
