import axios from "axios";

const setReport = async (reportappointment) => {
  try {
    const response = await axios.post(
      `https://report.dilvekonusmaterapim.com/api/appointments`,
      {
        ...reportappointment,
      }
    );

    if (response.status == 200) {
      return response.data;
    }
    return null;
  } catch (e) {
    return null; //UserNotFound
  }
};

export default {
  setReport,
};
